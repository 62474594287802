import React, { useState, useRef, useEffect } from 'react';
import ThreeDotsIcon from 'icons/ThreeDots';
import styled from 'styled-components';
import { disabledTextColor } from 'config/theme';
import { useClickOutside } from '@theorem/react';
import Options from './Options';
import { IPopoverOption } from './Option';
import { IMMUTABLE_HTML_ATTRIBUTE } from 'utils/constants';
import StyledReactTooltip from 'components/ToolTip/StyledReactTooltip';

export interface IPopoverProps {
  /**
   * Deprecated: If true, the z index of the popover popup will be adjusted so that it is not
   * covered by the currently-displayed modal.
   */
  modalIsVisible?: boolean;
  /**
   * the popover items that will appear when the trigger is clicked.
   */
  options: IPopoverOption[];
  id?: string;
  /**
   * Optional: If our Popover has a header, we want to set this as true. This will
   * make the underlying containers remove some extra padding we add to divs so
   * that things look symmetrical when there is no header.
   */
  hasHeader?: boolean;
  /**
   * Optional: If true, the active styling is persisted on the handle
   */
  active?: boolean;
  /**
   * Optional: If true, disables the hover and active states on the popover handle.
   */
  plain?: boolean;
  /**
   * Optional: the width of the options popup.
   */
  popupWidth?: number | string;
  /**
   * Optional: the max-height of the options popup.
   */
  popupMaxHeight?: number | string;
  /**
   * Controls whether the popover is disabled or not.
   */
  disabled?: boolean;

  leftAlign?: boolean;
  handle?: React.ReactNode;
  className?: string;
  dataFor?: string;
  /**
   * Optional: callback that will be triggered when the popover opens.
   */
  onOpen?: () => void;
  /**
   * Optional: callback that will be triggered when the popover closes.
   */
  onClose?: () => void;
  /**
   * Optional: manually close the popover from the parent.
   */
  forceClose?: boolean;
  /**
   * Optional: set popover vertical position manually
   */
  popupPosition?: 'bottom' | 'top';
  /**
   * Optional: for pendo tracking
   */
  pendoId?: string;
  /**
   * Optional: If our Page has a sticky footer, we want to set this as true. This will
   make the popover position to adjust itself according to the window height and show at top if there is no space
   to represent all the options at the bottom.
  */
  hasFooter?: boolean;
  tooltip?: string;
  tooltipId?: string;
  /**
   * Optional: define the popover data-testid, if not provided defaults the data-testid to "popover-handle"
   */
  dataTestId?: string;
  /**
   * Optional: sets role for popover handle element
   */
  popoverHandleRole?: string;

  /**
   * Optional: callback that will be triggered to prevent the default event or prevent further propagation
   */
  onPreventEvent?: (e: React.MouseEvent) => void;
  /**
   * Optional: Popover handle hover override
   */
  popoverHandleHover?: string;
  /**
   * Optional: Popover handle active override
   */
  popoverHandleActive?: string;
  zIndex?: number;
  popOverWidth?: string;
  popOverHeight?: string;
}

export function Popover({
  className,
  dataFor,
  id,
  disabled = false,
  hasHeader = false,
  hasFooter = false,
  handle,
  leftAlign,
  active,
  plain,
  onClose,
  onOpen,
  forceClose,
  options,
  popupWidth,
  popupMaxHeight,
  popupPosition,
  pendoId,
  tooltip,
  tooltipId,
  dataTestId = 'popover-handle',
  popoverHandleRole,
  onPreventEvent,
  popoverHandleActive,
  popoverHandleHover,
  zIndex,
  popOverWidth,
  popOverHeight,
}: IPopoverProps) {
  const didMount = useRef(false);
  const [open, setOpen] = useState(false);
  const popoverHandleRef = useRef<HTMLDivElement | null>(null);
  const outside = useClickOutside(() => {
    setOpen(false);
  });
  const isOpen = open && !disabled;

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    } else {
      isOpen ? onOpen?.() : onClose?.();
    }
  }, [isOpen, onClose, onOpen]);

  useEffect(() => {
    if (forceClose) setOpen(false);
  }, [forceClose]);

  const showTooltip = !!(tooltip && tooltipId);

  return (
    <PopoverContainer
      id={`${id}-container` || `popover-container`}
      className={className}
      data-for={dataFor}
    >
      <div ref={popoverHandleRef}>
        <PopoverHandle
          width={popOverWidth}
          height={popOverHeight}
          className="popover-handle"
          disabled={disabled}
          active={active}
          plain={plain}
          onClick={(ev) => {
            if (onPreventEvent) onPreventEvent(ev);
            ev.preventDefault(); // TODO: Ideally move this to the onPreventEvent callback, but breaks other functions
            setOpen(!open);
          }}
          open={isOpen}
          data-testid={dataTestId}
          data-tip={tooltipId}
          data-for={tooltipId}
          ref={outside.additionalArea('popoverHandle')}
          {...{
            [IMMUTABLE_HTML_ATTRIBUTE]: pendoId || dataFor,
          }}
          role={popoverHandleRole}
          popOverHandleHover={popoverHandleHover}
          popOverHandleActive={popoverHandleActive}
        >
          {handle || <ThreeDotsIcon />}
        </PopoverHandle>
        {showTooltip && (
          <StyledReactTooltip
            maxWidth="auto"
            content={tooltip}
            id={tooltipId}
          />
        )}
      </div>
      {isOpen && (
        <div ref={outside}>
          <Options
            zIndex={zIndex}
            hasHeader={hasHeader}
            hasFooter={hasFooter}
            leftAlign={leftAlign}
            options={options}
            id={id}
            popupWidth={popupWidth}
            popupMaxHeight={popupMaxHeight}
            setOpen={setOpen}
            popupPosition={popupPosition}
            triggerRef={popoverHandleRef.current}
          />
        </div>
      )}
    </PopoverContainer>
  );
}
Popover.displayName = 'Popover';

export const PopoverContainer = styled.div`
  position: relative;
`;

export const ICON_HOVER_BACKGROUND = 'rgba(122, 122, 122, 0.14)';
export const ICON_ACTIVE_BACKGROUND = 'rgba(122, 122, 122, 0.26)';

export const PopoverHandle = styled.div<{
  disabled?: boolean;
  plain?: boolean;
  active?: boolean;
  open: boolean;
  popOverHandleHover?: string;
  popOverHandleActive?: string;
  width?: string;
  height?: string;
}>`
  background-color: ${({ open, plain, popOverHandleActive }) =>
    open && !plain
      ? popOverHandleActive || ICON_ACTIVE_BACKGROUND
      : 'transparent'};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  width: ${({ width }) => width || '40px'};
  height: ${({ height }) => height || '40px'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ plain, popOverHandleActive, popOverHandleHover }) =>
    plain
      ? ''
      : `
    :not([disabled]) {
      &:hover {
        background-color: ${popOverHandleHover || ICON_HOVER_BACKGROUND};
      }
      &:active {
        background-color: ${popOverHandleActive || ICON_ACTIVE_BACKGROUND};
      }
    }
  `}
  ${({ active, popOverHandleActive }) =>
    active
      ? `background-color: ${popOverHandleActive || ICON_ACTIVE_BACKGROUND};`
      : ''}

  &[disabled] {
    path {
      fill: ${disabledTextColor};
    }
  }
`;
PopoverHandle.displayName = 'PopoverHandle';
