import { AxiosError } from 'axios';
import { IPagination } from 'store/common/types';
import {
  FileAssociationStatus,
  WireInstructionStatus,
} from 'store/wireInstructions/types';
import { IOrderPartyContactsHash } from 'store/orders/types';
import IInternationalWireQuestionnaires from '../../components/WireInstructions/common/type';

export enum UniversalWireAccountsTypeKeys {
  ENTITY = '[universalWireInstructions]',

  FETCH_ALL_REQUEST = 'universalWireAccountsType/FETCH_ALL_REQUEST',
  FETCH_ALL_SUCCESS = 'universalWireAccountsType/FETCH_ALL_SUCCESS',
  FETCH_ALL_ERROR = 'universalWireAccountsType/FETCH_ALL_ERROR',
  CLEAR_ERROR = 'universalWireAccountsType/CLEAR_ERROR',
  SET_UNIVERSAL_WIRE_INSTRUCTION_DETAILS = 'universalWireAccountsType/SET_UNIVERSAL_WIRE_INSTRUCTION_DETAILS',
  SET_UNIVERSAL_WIRE_INSTRUCTION_FILE_ASSOCIATION = 'universalWireAccountsType/SET_UNIVERSAL_WIRE_INSTRUCTION_FILE_ASSOCIATION',

  SET_UNIVERSAL_WIRE_INSTRUCTIONS = 'SET_UNIVERSAL_WIRE_INSTRUCTIONS',
  RESET_UNIVERSAL_WIRE_STATE = 'RESET_UNIVERSAL_WIRE_STATE',
}

export interface IUniversalWireAccountsState {
  universalWireAccounts: IUniversalWireAccount[];
  universalWireAccountDetails: IUniversalWireAccountDetails | null;
  universalWireAccountFileAssociation: IUniversalWireAccountFileAssocation | null;
  loading: boolean;
  error: null | AxiosError;
  pagination: IPagination | null;
}

export enum UniversalWireInstructionLoadingKeys {
  universalWireInstructionDetailLoading = 'universalWireInstructionDetailLoading',
  universalWireInstructionFileAssocLoading = 'universalWireInstructionFileAssocLoading',
}

interface IFetchUniversalWireAccountsRequestAction {
  type: UniversalWireAccountsTypeKeys.FETCH_ALL_REQUEST;
}

interface IFetchUniversalWireAccountsErrorAction {
  type: UniversalWireAccountsTypeKeys.FETCH_ALL_ERROR;
  error: null | AxiosError;
}

interface ISetUniversalWireAccountDetailsAction {
  type: UniversalWireAccountsTypeKeys.SET_UNIVERSAL_WIRE_INSTRUCTION_DETAILS;
  universalWireAccountDetails: IUniversalWireAccountDetails;
}

interface ISetUniversalWireAccountFileAssociationAction {
  type: UniversalWireAccountsTypeKeys.SET_UNIVERSAL_WIRE_INSTRUCTION_FILE_ASSOCIATION;
  universalWireAccountFileAssociation: IUniversalWireAccountFileAssocation;
}

export interface ISetUniversalWireInstructionsAction {
  type: UniversalWireAccountsTypeKeys.SET_UNIVERSAL_WIRE_INSTRUCTIONS;
  universalWireAccounts: IUniversalWireAccount[];
  pagination: IPagination;
}

export enum UniversalWireKey {
  universalWireAccounts = 'universalWireAccounts',
  universalWireAccountDetails = 'universalWireAccountDetails',
  universalWireAccountFileAssociation = 'universalWireAccountFileAssociation',
}

export interface IResetUniversalWireStateProps {
  key: UniversalWireKey;
  value?: any | null;
}

export interface IClearUniversalWireStateAction {
  type: UniversalWireAccountsTypeKeys.CLEAR_ERROR;
}

export interface IResetUniversalWireStateAction
  extends IResetUniversalWireStateProps {
  type: UniversalWireAccountsTypeKeys.RESET_UNIVERSAL_WIRE_STATE;
}

export type UniversalWireAccountsAction =
  | IFetchUniversalWireAccountsRequestAction
  | IFetchUniversalWireAccountsErrorAction
  | ISetUniversalWireAccountDetailsAction
  | ISetUniversalWireAccountFileAssociationAction
  | ISetUniversalWireInstructionsAction
  | IResetUniversalWireStateAction
  | IClearUniversalWireStateAction;

interface IUniversalWireAccount {
  id: number;
  accountHolderName: string;
  lastUpdated: string;
  lastStatusChange: string;
  wireInstructionStatus: WireInstructionStatus;
  fileAssociationStatus: FileAssociationStatus;
  fileId: number;
  fileNumber: string;
  dealPartyName: string;
  dealPartyOrganizationName: string;
  dealPartyOrganizationId: number;
  dealPartyRole: string;
  dealPartyEmail: string;
  isLocked: boolean;
}

export interface IUniversalWireAccountDetails {
  id: number;
  accountHolderName: string;
  accountNumber: string;
  bankName: string;
  note1: string;
  note2: string;
  note3: string;
  note4: string;
  documentUrl: string;
  bankRoutingNumber: string;
  dealPartyOrganizationId: number;
  dealPartyOrganizationName: string;
  status: string;
  isWireUpdated: boolean;
  originalFileId: number;
  originalFileNumber: string;
  originalWireInstructionFileId: number;
  safeValidationCode: string;
  safeValidationMessage: string;
  safeValidationFriendlyMessage?: string;
  severity?: string;
  isPayoffAccount: boolean | null;
  payeeTypeValue: boolean | null;
  verificationExpirationDate?: Date;
  isWireVerified?: boolean;
  isLocked: boolean;
  beneficiarySwiftCode: string;
  beneficiaryBankName: string;
  accountHolderNameDifferenceReason: string;
  isInternational: boolean;
  internationalWireQuestionnaires: IInternationalWireQuestionnaires[];
  isIntWireQuestionsUpdated: boolean;
  finalizeWire?: boolean;
}

export interface IUniversalWireAccountFileAssocation {
  id: number;
  code: string;
  wireInstructionId: number;
  dealPartyOrganizationId: number;
  dealPartyOrganizationName: string;
  organizationContacts: IOrderPartyContactsHash;
  dealPartyName: string;
  dealPartyEmail: string;
  dealPartyRole: string;
  fileId: number;
  fileNumber: string;
  status: string;
  lastUpdated: string;
  externalDocuments: IWireDocument[];
  wasCustomerNotified: boolean;
  lastNotifiedCustomerName: string;
  attachedDisbursementsCount?: number;
  disbursementIds: number[];
}

export interface IWireDocument {
  externalDocumentId: number;
  externalDocumentName: string;
  externalDocumentSize: number;
}
